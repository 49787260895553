// ./src/azure-storage-blob.ts

// <snippet_package>
// THIS IS SAMPLE CODE ONLY - NOT MEANT FOR PRODUCTION USE
import { BlobServiceClient } from "@azure/storage-blob";

const containerName = `storage/old/course`;
const sasToken = process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN;
const storageAccountName = process.env.REACT_APP_AZURE_STORAGE_RESOURCE_NAME;
// </snippet_package>

// <snippet_get_client>
const uploadUrl = `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`;

// get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
const blobService = new BlobServiceClient(uploadUrl);

// get Container - full public read access
const containerClient = blobService.getContainerClient(containerName);
// </snippet_get_client>

const createBlobInContainer = async ({ file, onProgressPercentage }) => {
  const fsize = file.size;

  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(file.name);

  // set mimetype as determined from browser with file upload control
  const options = {
    blobHTTPHeaders: { blobContentType: file.type },
    onProgress: (progresss) => {
      const percentage = Math.round((progresss.loadedBytes / fsize) * 100);
      onProgressPercentage(percentage);
    },
  };

  // upload file
  const res = await blobClient.uploadBrowserData(file, options);
  return res;
};
// </snippet_createBlobInContainer>

// <snippet_uploadFileToBlob>
export const uploadFileToBlob = async ({ file, onProgressPercentage }) => {
  if (!file) return;

  // upload file
  const res = await createBlobInContainer({ file, onProgressPercentage });
  return res;
};
// </snippet_uploadFileToBlob>
