import { load } from "@syncfusion/ej2-react-richtexteditor";
import { useContent } from "..";
import { ContentLayoutV2, PageSection, SimpleTableLayout, ModalLayout } from "shared";
import { Button } from "reactstrap";
import { ContentModal, ImageVideoModal } from "../components";
import { Buffer } from "buffer";
import { useLocation } from "react-router-dom";

export const ContentList = () => {
  const {
    contentList,
    isContentModal,
    contentModalOperation,
    uploadCourseContent,
    setContentData,
    contentData,
    onDelete,
    deleteInfo,
    resetDeleteInfo,
    onConfirmDelete,
    imageVideoModalOperation,
    isImageVideoModal,
    progressCount,
    setProgressCount,
    createCambridgeCourseContent,
  } = useContent({ load: false });
  const { data, isLoading } = contentList;

  const location = useLocation();

  const onPreview = (row) => {
    if (row.original.isVideo) {
      imageVideoModalOperation(row.original.fileURL);
    } else {
      // const encFileName = Buffer.from(row.original.fileName, "utf-8").toString("base64");
      // window.open(`${location.pathname}/${encFileName}`);
      row.original.fileURL && window.open(row.original.fileURL);
    }
  };

  const ActionButtons = ({ row }) => {
    return (
      <>
        {/* <button className="table-action-btn text-link" onClick={() => onPreview(row)}>
          <span>Preview</span>
        </button> */}
        <button
          onClick={() => {
            onDelete({ name: row.original.title, id: row.original.contentId });
          }}
          className="table-action-btn"
        >
          <i className="bi bi-trash"></i>
        </button>
      </>
    );
  };

  const columns = [
    {
      Header: " Document Name",
      accessor: "title",
    },

    {
      Header: "Actions",
      accessor: "trainerId",
      id: "actions",
      Cell: ActionButtons,
    },
  ];

  return (
    <ContentLayoutV2>
      <PageSection>
        <div className="card-header d-flex align-items-center justify-content-between border-bottom mb-3">
          <h3 className="card-title mb-0">Contents</h3>
          <button className="section-btn" type="button" onClick={() => contentModalOperation()}>
            <i className="bi-plus"></i>
            <span>Add</span>
          </button>
        </div>
        <div className="card-body">
          {!isLoading && <SimpleTableLayout columns={columns} data={data ? data : ""} />}
        </div>
      </PageSection>
      <ContentModal
        isContentModal={isContentModal}
        toggle={contentModalOperation}
        uploadCourseContent={uploadCourseContent}
        createCambridgeCourseContent={createCambridgeCourseContent}
        setContentData={setContentData}
        contentData={contentData}
        progressCount={progressCount}
        setProgressCount={setProgressCount}
      />
      <ModalLayout
        isOpen={deleteInfo.show}
        title={"Confirm"}
        message={`Are you sure? Do you want to delete ${deleteInfo.name}`}
        onConfirm={onConfirmDelete}
        onCancel={resetDeleteInfo}
      />
      <ImageVideoModal
        imageVideoModalOperation={imageVideoModalOperation}
        isImageVideoModal={isImageVideoModal.isOpen}
        url={isImageVideoModal.url}
        size="md"
      />
    </ContentLayoutV2>
  );
};
